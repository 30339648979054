import preval from "preval.macro";
import { injectExtraBuildInfo, parseBuildInfo } from "@ingka-livlig/frontend-lib";

/**
 * Gets the build info for this app and inject it into the "global" build info setting, making sure that the "global"
 * build info is either this or the one for the frontend-lib, whatever is more recent.
 *
 * NOTE: this file needs to be copied to each app because of the usage of "preval".
 */
export function setupBuildInfo() {
    //At compile time get last commit date and sha for the current library. This needs to be copied verbatim here in
    // order for preval to work.
    const buildInfo = preval`
var execSync = require('child_process').execSync;
try {
  module.exports = execSync('git log -1 --format=%aI . && git log --pretty=tformat:\"%h\" -n1 .').toString().trim();
} catch (e) {
  module.exports = '';
}
`;
    injectExtraBuildInfo(parseBuildInfo(buildInfo));
}
