import {
    AppUser,
    FetchRequestInit,
    fetchWithUserCredentials,
    getBackendURL,
    getUserInfo,
    LocationScope,
    LocationScopeWithMetadata,
    publishEvents,
    UserEvent,
    UserEventPublisher,
} from "@ingka-livlig/frontend-lib";
import { useStore } from "react-context-hook";
import { useMemo } from "react";

const hostname = getBackendURL();

export type UserLocationDetails = {
    stored: LocationScopeWithMetadata | null;
    guessed: LocationScopeWithMetadata | null;
};

export interface RemoteLoginCreateResponse {
    sessionId: string;
    validateText: string;
    validUntil: string;
}

export interface RemoteLoginPollResponse {
    redirectUrl: string;
    locale: string;
}

export interface RemoteLoginFetchResponse {
    sessionId: string;
    validateText: string;
    validUntil: string;
}

export interface UserAPI extends UserEventPublisher {
    userLocation(): Promise<LocationScope | null>;

    userLocationWitMetadata(): Promise<LocationScopeWithMetadata | null>;

    userLocationDetails(): Promise<UserLocationDetails>;

    setUserLocation(location: LocationScope): Promise<void>;

    recordTranslation(payload: {
        author: string;
        language: string;
        original: string;
        existingTranslation: string;
        translated: string;
        comment?: string;
    }): Promise<void>;

    remoteLoginCreate(): Promise<RemoteLoginCreateResponse>;

    remoteLoginPoll(sessionId: string): Promise<RemoteLoginPollResponse | "expired" | undefined>;

    remoteLoginFetch(sessionId: string): Promise<RemoteLoginFetchResponse | "expired" | undefined>;

    remoteLoginValidate(sessionId: string, redirectUrl: string, locale: string): Promise<void>;
}

function userAPI(user: AppUser): UserAPI {
    async function fetchWithCredentials(input: RequestInfo, init?: FetchRequestInit): Promise<Response> {
        return fetchWithUserCredentials(user, input, init);
    }

    return {
        publishEvents: async (events: UserEvent<any>[]) => {
            await publishEvents(events, user, `${hostname}/api/events`);
        },
        userLocation: async () => {
            const result = await fetchWithCredentials(`${hostname}/api/v1/user/me/location`);
            if (result.status === 204) {
                return null;
            } else {
                return await result.json();
            }
        },
        userLocationWitMetadata: async () => {
            const result = await fetchWithCredentials(`${hostname}/api/v1/user/me/location?metadata=true`);
            if (result.status === 204) {
                return null;
            } else {
                return await result.json();
            }
        },
        userLocationDetails: async () => {
            const result = await fetchWithCredentials(`${hostname}/api/v1/user/me/location/details`);
            if (result.status === 204) {
                return null;
            } else {
                return await result.json();
            }
        },
        setUserLocation: async (location: LocationScope) => {
            await fetchWithCredentials(`${hostname}/api/v1/user/me/location`, {
                method: "PUT",
                body: JSON.stringify(location),
                headers: {
                    "Content-Type": "application/json",
                },
            });
        },
        recordTranslation: async (payload: {
            author: string;
            language: string;
            original: string;
            existingTranslation: string;
            translated: string;
            comment?: string;
        }) => {
            await fetchWithCredentials(`${hostname}/api/v1/translations`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
                method: "POST",
            });
        },
        async remoteLoginCreate(): Promise<RemoteLoginCreateResponse> {
            const result = await fetch(`${hostname}/remoteLogin`, {
                method: "POST",
            });
            return await result.json();
        },
        async remoteLoginPoll(sessionId: string): Promise<RemoteLoginPollResponse | "expired" | undefined> {
            //This will set the "auth" cookie so we need to set "credentials=include".
            const result = await fetch(`${hostname}/remoteLogin/${sessionId}/poll`, { credentials: "include" });
            if (result.status === 200) {
                return await result.json();
            } else if (result.status === 410) {
                return "expired";
            } else {
                return undefined;
            }
        },
        async remoteLoginFetch(sessionId: string): Promise<RemoteLoginFetchResponse | "expired" | undefined> {
            const result = await fetchWithCredentials(`${hostname}/remoteLogin/${sessionId}`, {
                allowedResponseCodes: [410],
            });
            if (result.status === 200) {
                return await result.json();
            } else if (result.status === 410) {
                return "expired";
            } else {
                return undefined;
            }
        },
        async remoteLoginValidate(sessionId: string, redirectUrl: string, locale: string): Promise<void> {
            const user = getUserInfo();
            await fetchWithCredentials(`${hostname}/remoteLogin/${sessionId}/validate`, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    redirectUrl: redirectUrl,
                    locale: locale,
                    displayName: user?.displayName,
                    userEmail: user?.mail,
                }),
            });
        },
    };
}

export function useUserAPI() {
    const [user] = useStore<AppUser>("user");
    return useMemo(() => userAPI(user), [user]);
}
