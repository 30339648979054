import React, { useEffect, useState } from "react";
import styles from "./Training.module.css";
import {
    blossomUrl,
    currentCrumb,
    itemSalesUrl,
    linkCrumb,
    LivligButton,
    LoadingIndicator,
    LocationScopeWithMetadata,
    Menu,
    Page,
    PageHeader,
    Segment,
    SegmentHeader,
    sellItUrl,
    TallButton,
    unlessSuccessful,
    useApiCall,
    useTrainingAPI,
} from "@ingka-livlig/frontend-lib";
import { t, Trans } from "@lingui/macro";
import { AppSettings } from "./AppSettings";
import { useUserAPI } from "./userAPI";
import Icon from "@ingka/ssr-icon";
import arrowForward from "@ingka/ssr-icon/paths/chevron-right-small";
import { Link, useParams } from "react-router-dom";
import RadioButton from "@ingka/radio-button";
import pathChat from "@ingka/ssr-icon/paths/speech-bubble";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import medal from "./assets/medal.svg";
import _ from "underscore";
import reward from "@ingka/ssr-icon/paths/reward";
import checkmark from "@ingka/ssr-icon/paths/checkmark";
import { BooleanParam, useQueryParam } from "use-query-params";
import { LoadingLinear } from "@ingka/loading";
import { useLingui } from "@lingui/react";

type Question = {
    instructions: React.JSX.Element;
    title: () => string;
    options: () => Array<string>;
    answer: () => string;
};

type Lesson = {
    title: string;
    description: string;
    pageLink: string;
    lessonId: string;
};

const getLessons = (userLocation: LocationScopeWithMetadata): Array<Lesson> => {
    let locationType = userLocation.scope;
    let locationId;
    let countryCode;

    switch (userLocation.scope) {
        case "Country":
            locationId = userLocation.countryCode;
            countryCode = userLocation.countryCode;
            break;
        case "SellingUnit":
            locationId = userLocation.sellingUnitCode;
            countryCode = userLocation.countryCode;
            break;
        default:
            locationType = "Country";
            locationId = "SE";
            countryCode = "SE";
    }

    const lessons = [
        {
            title: t`Livlig basics`,
            description: t`Get up to speed with the basics of Livlig`,
            pageLink: "/training/lesson/1",
            lessonId: "livlig-basics",
        },
        {
            title: t`Live sales of an item`,
            description: t`Track sales data on items of interest`,
            pageLink: `${itemSalesUrl()}/locations/${locationType}/${locationId}/Item/1demo-eds?showTraining=1`,
            lessonId: "live-sales-item-forecast",
        },
        {
            title: t`Groups overview`,
            description: t`What can you do with a group of items?`,
            pageLink: `${itemSalesUrl()}/locations/Country/${countryCode}/groups/1demo?showTraining=1`,
            lessonId: "groups-overview",
        },
        {
            title: t`History basics`,
            description: t`View the sales history and sales performance of an item or group`,
            pageLink: `${itemSalesUrl()}/locations/${locationType}/${locationId}/history/sales-performance/1demo?mode=weekly&t=1713636794&showTraining=1`,
            lessonId: "history-basics",
        },
        {
            title: t`Ranking basics`,
            description: t`What is ranking?`,
            pageLink: `${itemSalesUrl()}/locations/${locationType}/${locationId}/ranking/regional/1demo?showTraining=1`,
            lessonId: "ranking-basics",
        },
        {
            title: t`Sales details`,
            description: t`Dive into detailed sales figures over different time periods.`,
            pageLink: `${itemSalesUrl()}/locations/${locationType}/${locationId}/details?period=5&showTraining=1`,
            lessonId: "sales-details",
        },
        {
            title: t`Toplists`,
            description: t`Use different top lists to get insights into how well items performs.`,
            pageLink: `${itemSalesUrl()}/locations/${locationType}/${locationId}/toplists/profit?showTraining=1`,
            lessonId: "toplist",
        },
        {
            title: `Sell It`,
            description: t`A digital and automated version of sales steering concept "4 Aces and 1 King" for all the countries.`,
            pageLink: `${sellItUrl()}/locations/${locationType}/${locationId}?showTraining=1`,
            lessonId: "four-aces-one-king",
        },
        {
            title: `Blossom`,
            description: t`A visual basket analysis tool, leveraging data on frequently co-purchased items, allowing exploration of customer behavior over the past 35 days.`,
            pageLink: `${blossomUrl()}/locations/${locationType}/${locationId}/Items/70033766?showTraining=1`,
            lessonId: "bought-together",
        },
    ];
    const lessonsForSellingUnits = [
        {
            title: t`Spotlight basics`,
            description: t`React as soon as possible to changes in sales behavior which clearly deviates from what is expected`,
            pageLink: `${itemSalesUrl()}/locations/${locationType}/${locationId}/spotlights?showTraining=1`,
            lessonId: "spotlight-basics",
        },
    ];
    if (userLocation.scope === "Country" || userLocation.scope === "Global") {
        return lessons;
    } else {
        return lessons.concat(lessonsForSellingUnits);
    }
};

const questions: Array<Question> = [
    {
        instructions: (
            <>
                <p>
                    <Trans>
                        Livlig provides immediate feedback loops and actionable insights based on real-time sales data.
                        The goal of Livlig is to empower co-workers by helping them answer three pivotal questions:
                    </Trans>
                </p>
                <p>
                    <Trans>What is happening right now?</Trans>
                </p>
                <p>
                    <Trans>Are we doing well?</Trans>
                </p>
                <p>
                    <Trans>How can we improve?</Trans>
                </p>
            </>
        ),
        title: () => t`What is the goal for Sales to deliver on?`,
        options: () => [t`Realtime sales insights`, t`Financial reporting`, t`Stock management`],
        answer: () => t`Realtime sales insights`,
    },
    {
        instructions: (
            <>
                <p>
                    <Trans>
                        Livlig can add value to the sales co-workers, as it allows you to follow the sales of your
                        favorite products.
                    </Trans>
                </p>

                <p>
                    <Trans>
                        As an example - If you do activities in order to increase sales of a specific product, then you
                        and your co-workers can see the effect of your work live in Livlig as customers buy it.
                    </Trans>
                </p>
                <p>
                    <Trans>
                        You can follow the article in Livlig and check the performance continuously as you try to
                        improve your numbers. With a direct feedback loop on your activities, letting test and learn
                        what works and what doesn't, productivity and sales can improve.
                    </Trans>
                </p>
            </>
        ),
        title: () => t`How can Livlig support the sales activities?`,
        options: () => [
            t`by allowing for more effective reporting`,
            t`by helping co-workers understand the strategic products and the importance of those`,
            t`by empowering sales co-workers to test and learn in real-time`,
        ],
        answer: () => t`by empowering sales co-workers to test and learn in real-time`,
    },
    {
        instructions: (
            <>
                <p>
                    <Trans>
                        Spotlighting allows you to see large purchases or low sales of specific products. You can set
                        your specific preference you can and see and act on insights as Livlig discovers them.
                    </Trans>
                </p>
            </>
        ),
        title: () => t`What does the spotlight feature allow you to do?`,
        options: () => [
            t`Check what should be selling but for some reason is not`,
            t`High sales of a particular product`,
            t`all of the above`,
        ],
        answer: () => t`all of the above`,
    },
    {
        instructions: (
            <>
                <p>
                    <Trans>
                        In Livlig you can create groups of products and share them with you co-workers! This allows you
                        to check the sales of a set of of products, like a hot spot or a media, and act on what can be
                        improved in the store immediately. You can easily share your groups through the share button or
                        by copying and sending the url. Through this your team can see and work with the same groups,
                        saving time and double work.
                    </Trans>
                </p>
            </>
        ),
        title: () => t`How do I share a group with other co-workers?`,
        options: () => [t`Copy the url and send`, t`Share the link with the share button`, t`all of the above`],
        answer: () => t`all of the above`,
    },
    {
        instructions: (
            <>
                <p>
                    <Trans>
                        Livlig collects every transaction between INGKA and retail customers as they happen in near
                        real-time.
                    </Trans>
                </p>
                <p>
                    <Trans>
                        To go into details, for in-store receipts the main data sources is iPOS (via CTM). For eCommerce
                        a variety sources are used, such as IRIS (via CTM) and iSell and ISOM (via SOIM).
                    </Trans>
                </p>
            </>
        ),
        title: () => t`How often is Sales updated?`,
        options: () => [t`Every hour`, t`With every receipt/invoice`, t`On every day`],
        answer: () => t`With every receipt/invoice`,
    },
];

type CurrentViewOptions = "Instruction" | "Question";

export const Training: React.FC = () => {
    useLingui(); // Needed to make the t`` macro work

    const totalSteps = questions.length;
    const [showFirstTimeGuide, setShowFirstTimeGuide] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [completedTrainings, setCompletedTrainings] = useState<Array<string> | null>(null);
    const [currentView, setCurrentView] = useState<CurrentViewOptions>("Instruction");
    const [currentAnswer, setCurrentAnswer] = useState<string | null>(null);
    const [correctAnswer, setCorrectAnswer] = useState<boolean | null>(null);
    const progressValue = (currentStep / totalSteps) * 100;
    const currentQuestion = questions[currentStep] || null;
    const { width, height } = useWindowSize();
    const { lessonId } = useParams<{
        lessonId: string;
    }>();

    const [showStats] = useQueryParam("showStats", BooleanParam);
    const { listTrainings, putTraining, getUserCount, getTrainingStats } = useTrainingAPI();
    const trainingsRequest = useApiCall(listTrainings);

    const userCountRequest = useApiCall(getUserCount);
    const trainingStatsRequest = useApiCall(getTrainingStats);

    useEffect(() => {
        if (trainingsRequest.tag === "success") {
            setCompletedTrainings(trainingsRequest.value.names);
        }
    }, [trainingsRequest]);

    useEffect(() => {
        setCurrentStep(0);
        setCurrentView("Instruction");
        setCurrentAnswer(null);
        setCorrectAnswer(null);
        if (lessonId === "livlig-basics") {
            setShowFirstTimeGuide(false);
        } else {
            setShowFirstTimeGuide(true);
        }
    }, [lessonId]);

    const userLocationReq = useApiCall(useUserAPI().userLocationWitMetadata);
    const userLocation = unlessSuccessful(userLocationReq, null) || {
        scope: "Global",
        currency: { name: "Euro", code: "EUR" },
    };

    if (userLocationReq.tag === "loading") {
        return <LoadingIndicator />;
    }

    const lessons = getLessons(userLocation);

    const goToQuestion = () => {
        setCurrentView("Question");
    };
    const medalImg = <img src={medal} className={styles.medalIcon} alt="medal-icon" />;

    const getLesson = () => {
        if (lessonId) {
            const index = _.findIndex(lessons, (lesson) => {
                return lesson.lessonId === lessonId;
            });
            return lessons[index];
        }
        return null;
    };
    const currentLesson = getLesson();

    const getCourseCount = (lesson: string): number => {
        if (trainingStatsRequest.tag === "success") {
            const stat = trainingStatsRequest.value.stats.find((s) => s.name === lesson);
            if (stat) {
                return stat.count;
            }
        }
        return 0;
    };

    return (
        <Page
            title={t`Training`}
            headerElement={
                <PageHeader
                    headerText={
                        <a href={"/"} className={styles.headerLink}>
                            Livlig
                        </a>
                    }
                    actionElement={[<AppSettings key={"settings"} />]}
                >
                    <Menu currentApp="training" />
                </PageHeader>
            }
            breadcrumbItems={
                lessonId
                    ? [linkCrumb(t`Training`, "/training"), currentCrumb(currentLesson?.title || "")]
                    : [currentCrumb(t`Training`)]
            }
        >
            <div className={styles.container}>
                <div style={{ width: "100%" }}>
                    {!lessonId && (
                        <div className={styles.firstStep}>
                            <SegmentHeader>
                                {medalImg}
                                <Trans>Training</Trans>
                                {medalImg}
                            </SegmentHeader>
                            {showStats && userCountRequest.tag === "success" && (
                                <div>{userCountRequest.value} user(s) have completed at least one training</div>
                            )}
                            <Segment variant="primary" padded>
                                <ul className={styles.educationHeader}>
                                    {lessons.map((lesson) => {
                                        return (
                                            <li className={styles.educationList} key={lesson.lessonId}>
                                                <a
                                                    className={styles.educationItem}
                                                    href={`/training/lesson/${lesson.lessonId}`}
                                                >
                                                    <div>
                                                        <h5 className={styles.listHeader}>{lesson.title}</h5>
                                                        <p className={styles.listInfo}>{lesson.description}</p>
                                                        {completedTrainings &&
                                                        completedTrainings.includes(lesson.lessonId) ? (
                                                            <div className={styles.completed}>
                                                                <Icon paths={checkmark} />
                                                                <span>
                                                                    <Trans>completed</Trans>
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className={styles.todo}>
                                                                <Icon paths={reward} />
                                                                <span>
                                                                    <Trans>to do</Trans>
                                                                </span>
                                                            </div>
                                                        )}
                                                        {showStats && trainingStatsRequest.tag === "success" && (
                                                            <div>
                                                                {getCourseCount(lesson.lessonId)} user(s) completed this
                                                                training
                                                            </div>
                                                        )}
                                                    </div>
                                                    <aside>{<Icon paths={arrowForward} />}</aside>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Segment>
                        </div>
                    )}
                    {lessonId && showFirstTimeGuide && (
                        <div className={styles.topic}>
                            <SegmentHeader>{currentLesson?.title || ""}</SegmentHeader>
                            <Segment variant="primary" padded>
                                <div className={styles.lessonDescription}>
                                    <p>{currentLesson?.description || ""}</p>
                                    <p>
                                        <Trans>Let's do a quick walkthrough</Trans>
                                    </p>
                                </div>
                                <a className={styles.startButton} href={currentLesson?.pageLink || ""}>
                                    <LivligButton type={"primary"}>
                                        <Trans>Start</Trans>
                                    </LivligButton>
                                </a>
                            </Segment>
                        </div>
                    )}

                    {lessonId && !showFirstTimeGuide && (
                        <div className={styles.secondStep}>
                            <Segment variant="primary" padded>
                                <div className={styles.header}>
                                    <SegmentHeader>
                                        <Trans>Livlig basics</Trans>
                                    </SegmentHeader>
                                    <h3 className={styles.progressCount}>
                                        {currentStep >= totalSteps ? totalSteps : currentStep + 1}/{totalSteps}
                                    </h3>
                                </div>
                                <div className={styles.progressBar}>
                                    <LoadingLinear loadingValue={progressValue} />
                                </div>

                                {currentStep === totalSteps && (
                                    <div className={styles.finalStep}>
                                        <h2 className={styles.finalHeader}>
                                            <Trans>Well done!</Trans>
                                        </h2>
                                        <Confetti width={width} height={height} />
                                        <Link className={styles.linkStyles} to={"/training"}>
                                            <LivligButton type={"primary"}>
                                                <Trans>Continue</Trans>
                                            </LivligButton>
                                        </Link>
                                    </div>
                                )}

                                {currentQuestion && (
                                    <div id="instructions" className={styles.instructions}>
                                        {currentView === "Instruction" && (
                                            <>
                                                {currentQuestion.instructions}
                                                <div className={styles.continueQuestionButton}>
                                                    <LivligButton type={"primary"} onClick={goToQuestion}>
                                                        <Trans>Continue</Trans>
                                                    </LivligButton>
                                                </div>
                                            </>
                                        )}
                                        {currentView === "Question" && (
                                            <div id="firstQuestion">
                                                <h3 className={styles.question}>{currentQuestion.title()}</h3>

                                                {currentQuestion.options().map((option) => {
                                                    return (
                                                        <RadioButton
                                                            value={""}
                                                            key={option}
                                                            name={option}
                                                            id={option}
                                                            checked={currentAnswer === option}
                                                            disabled={correctAnswer === false}
                                                            onChange={() => setCurrentAnswer(option)}
                                                            label={option}
                                                            className={styles.questionItem}
                                                        />
                                                    );
                                                })}

                                                {correctAnswer === null && (
                                                    <div className={styles.checkButton}>
                                                        <LivligButton
                                                            type={"primary"}
                                                            disabled={currentAnswer === null}
                                                            onClick={() => {
                                                                setCorrectAnswer(
                                                                    currentAnswer === currentQuestion.answer(),
                                                                );
                                                            }}
                                                        >
                                                            <Trans>Check</Trans>
                                                        </LivligButton>
                                                    </div>
                                                )}

                                                {correctAnswer === true && (
                                                    <div className={styles.correctAnswer}>
                                                        <p className={styles.correctAnswerText}>
                                                            <Trans>Nice, correct answer!</Trans>
                                                        </p>
                                                        <div className={styles.continueButton}>
                                                            <LivligButton
                                                                type={"primary"}
                                                                className={styles.alignButton}
                                                                onClick={async () => {
                                                                    setCurrentStep(currentStep + 1);
                                                                    setCurrentView("Instruction");
                                                                    setCurrentAnswer(null);
                                                                    setCorrectAnswer(null);
                                                                    if (currentStep + 1 === totalSteps) {
                                                                        await putTraining("livlig-basics");
                                                                        if (trainingsRequest.tag === "success") {
                                                                            trainingsRequest.refresh();
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <Trans>Continue</Trans>
                                                            </LivligButton>
                                                            <Link className={styles.linkStyles} to={"/training"}>
                                                                <LivligButton type={"secondary"}>
                                                                    <Trans>Come back later</Trans>
                                                                </LivligButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}

                                                {correctAnswer === false && (
                                                    <div className={styles.wrongAnswer}>
                                                        <p className={styles.wrongAnswerText}>
                                                            <Trans>Sorry, but thats not correct!</Trans>
                                                        </p>
                                                        <div className={styles.continueButton}>
                                                            <LivligButton
                                                                type={"primary"}
                                                                className={styles.alignButton}
                                                                onClick={() => {
                                                                    setCurrentView("Instruction");
                                                                    setCurrentAnswer(null);
                                                                    setCorrectAnswer(null);
                                                                }}
                                                            >
                                                                <Trans>Try again</Trans>
                                                            </LivligButton>
                                                            <Link className={styles.linkStyles} to={"/training"}>
                                                                <LivligButton type={"secondary"}>
                                                                    <Trans>Come back later</Trans>
                                                                </LivligButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Segment>
                        </div>
                    )}
                </div>
            </div>
            <Segment variant={"secondary"} padded>
                <TallButton
                    id="feedbackButton"
                    iconPath={pathChat}
                    title={t`Write to us on Viva Engage`}
                    href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzMTAzNjI4NDkyOCJ9"
                    type={"tertiary"}
                    className={styles.feedback}
                >
                    <Trans>Give Feedback</Trans>
                </TallButton>
            </Segment>
        </Page>
    );
};
