import { AppUser, fetchWithUserCredentials, getBackendURL } from "@ingka-livlig/frontend-lib";
import { useStore } from "react-context-hook";
import { useMemo } from "react";

const hostname = getBackendURL();

export type SellingUnit = {
    id: string;
    name: string;
    isInternal: boolean;
};

export type Country = {
    id: string;
    name: string;
    sellingUnits: Array<SellingUnit>;
};

export interface MetadataAPI {
    countries(): Promise<{ countries: Country[] }>;

    getSellingUnit(bu: string): Promise<SellingUnit>;
}

function metadataAPI(user: AppUser): MetadataAPI {
    return {
        countries: async () => {
            const result = await fetchWithUserCredentials(user, `${hostname}/api/v1/metadata/sellingunits`);
            return await result.json();
        },
        getSellingUnit: async (bu: string) => {
            const result = await fetchWithUserCredentials(user, `${hostname}/api/v1/metadata/sellingunits/${bu}`);
            return await result.json();
        },
    };
}

export function useMetadataAPI() {
    const [user] = useStore<AppUser>("user");
    return useMemo(() => metadataAPI(user), [user]);
}
